import React, { useState } from 'react';
import SectionHeading from '../SectionHeading/SectionHeading';

const Department = () => {
  const [isActive, setIsActive] = useState(0);

  return (
    <section id="department">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title='Nos Services'
        subTitle=
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br /> Lorem Ipsum the industry's standard dummy text." />
      <div className="container">
        <div className="st-tabs st-fade-tabs st-style1">
          <ul className="st-tab-links st-style1 st-mp0">
            <li className={`st-tab-title ${isActive === 0 ? "active" : ""}`} onClick={() => setIsActive(0)}>
              <span className="st-blue-box">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 458.52 458.52">
                  <g>
                    <g>
                      <g>
                        <path d="M218.776,271.586c-23.856,0-44.034,0-44.034,0l15.004,52.377l-29.822,85.226c-3.861,11.037,1.955,23.114,12.991,26.976
                          c11.039,3.862,23.115-1.958,26.976-12.991l32.044-91.579c1.446-4.134,1.575-8.613,0.369-12.823L218.776,271.586z"/>
                        <path d="M274.806,271.587c-13.159,0-30.84,0-47.678,0l24.555,48.141l-3.183,95.756c-0.388,11.686,8.771,21.474,20.456,21.863
                          c0.24,0.008,0.478,0.012,0.716,0.012c11.367,0,20.767-9.022,21.147-20.468l3.364-101.215c0.119-3.581-0.672-7.131-2.3-10.323
                          L274.806,271.587z"/>
                        <circle cx="257.237" cy="57.342" r="36.182"/>
                        <path d="M98.908,255.398h138.153l-30.254-14.663c-2.761-1.338-5.29-3.015-7.559-4.96H98.908
                          c-40.652,0-73.726,33.073-73.726,73.726v108.234c-8.062,0-7.411,0-15.371,0c-5.418,0-9.811,4.393-9.811,9.811
                          c0,5.418,4.393,9.811,9.811,9.811h50.365c5.418,0,9.811-4.393,9.811-9.811c0-5.418-4.393-9.811-9.811-9.811
                          c-8.033,0-7.41,0-15.371,0V309.503C44.806,279.669,69.076,255.398,98.908,255.398z"/>
                        <path d="M294.384,224.227l-55.79-27.04l-9.188-67.544l22.601,54.924l17.778,8.617l8.657-56.926
                          c1.798-11.821-6.328-22.86-18.148-24.658l-48.615-7.393c-11.82-1.798-22.86,6.328-24.658,18.148l-14.786,97.232h16.372
                          c-1.452-4.668-1.558-7.661-1.364-9.687l6.982-72.945l10.146,74.585c0.81,5.948,4.541,11.095,9.942,13.713
                          c39.757,19.269,16.838,8.16,64.437,31.231c8.135,3.944,18.166,1.211,23.052-6.706
                          C307.417,240.672,303.883,228.833,294.384,224.227z"/>
                        <path d="M448.708,417.737c-8.762,0-7.206,0-15.816,0V309.503c0-40.653-33.073-73.726-73.726-73.726h-37.788
                          c0.864,6.491-0.07,13.288-3.074,19.623h40.862c29.833,0,54.104,24.271,54.104,54.104v108.234c-9.356,0-11.435,0-11.82,0
                          c-0.055,0-0.078,0-0.08,0c-0.213,0-0.926,0-3.026,0c-5.418,0-9.811,4.393-9.811,9.811c0,5.418,4.393,9.811,9.811,9.811h50.365
                          c5.418,0,9.811-4.393,9.811-9.811C458.52,422.13,454.126,417.737,448.708,417.737z"/>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Rééducation</span>
              </span>
            </li>
            <li className={`st-tab-title ${isActive === 1 ? "active" : ""}`} onClick={() => setIsActive(1)}>
              <span className="st-blue-box">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"viewBox="0 0 945.405 945.405">
                  <g>
                    <path d="M615.6,894.153H0v-57.4c11.2,8.9,25.5,14.301,40.9,14.301h532.2L615.6,894.153z M266.4,51.252c-33,0-59.8,26.8-59.8,59.8
                      c0,20.5,10.3,38.6,26.1,49.4c6.8-18.8,17.8-36.1,32.4-50.7s31.9-25.6,50.7-32.4C305,61.553,286.9,51.252,266.4,51.252z
                      M249.3,207.953c0,63,51.101,114,114,114c62.9,0,114-51.1,114-114c0-63-51.1-114-114-114c-13.399,0-26.2,2.3-38.1,6.5
                      c-32.3,11.5-58,37.1-69.4,69.4C251.6,181.853,249.3,194.652,249.3,207.953z M187.4,365.252c-26.7,59.2-84.9,210.5-100.9,248.6
                      h198.6c4.4-10.799,9.301-21.299,14.7-31.199c18.7-34.9,77.101-52.201,63.2-100.9c-0.1-0.4-0.3-0.801-0.4-1.301l52.301,49.602
                      c3.899,3.699,8.6,6.699,13.6,8.6l143,54.6c4.8,1.801,9.8,2.699,14.7,2.699c16.6,0,32.2-10.1,38.399-26.5
                      c8.101-21.199-2.5-45-23.8-53.1L474.4,468.053c-5.9-2.3-11.301-5.7-15.9-10c0,0-111.3-105.6-111.4-105.7
                      c-4.699-4.5-10.8-7.4-17.199-9.5c-1.5-0.5-3.101-1-4.7-1.4c-1.7-0.5-3.5-1-5.3-1.4c-27.801-7.4-58.7-13.7-87.3-11.2
                      c-9.4,0.8-19.7,3.2-27.7,8.7C195.7,344.152,191.9,355.353,187.4,365.252z M40.9,826.053h542.6l52,52.699c4.9,5,10.8,8.9,17.1,11.5
                      c6.2,2.5,12.801,3.9,19.601,3.9h221.6c28.8,0,52-23.6,51.601-52.4c-0.5-28.301-24.101-50.699-52.4-50.699H693.8L558.4,653.653
                      c-10.5-10.701-16.7-15.1-35.5-15.1c-32.4,0-482,0.299-482,0.299c-22.6,0-40.9,18.301-40.9,40.9v94.801v10.6
                      C0,807.752,18.3,826.053,40.9,826.053z M871,637.452c0-65.299-53-118.299-118.3-118.299s-118.3,53-118.3,118.299
                      c0,65.301,53,118.301,118.3,118.301S871,702.852,871,637.452z"/>
                  </g>
                </svg>
                <span>Massage</span>
              </span>
            </li>
            <li className={`st-tab-title ${isActive === 2 ? "active" : ""}`} onClick={() => setIsActive(2)}>
              <span className="st-blue-box">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <g>
                  <g>
                    <g>
                      <path d="M223.8,223.771l72.733-17.158c3.156-0.983,35.744-24.385,35.744-24.385v80.211h116.056v-83.646h0.001
                        c0-40.386-32.739-73.125-73.125-73.125h-23.673c-0.562,0-1.121,0.02-1.676,0.05c-5.767-0.371-11.677,1.459-16.355,5.579
                        l-54.79,48.178l-68.324,21.217c-11.899,3.703-18.539,16.349-14.835,28.243C199.258,220.833,211.901,227.474,223.8,223.771z"/>
                      <path d="M94.768,150.19c3.136,5.073,8.563,7.863,14.119,7.863c2.975,0,5.987-0.802,8.704-2.481
                        c7.789-4.815,10.2-15.034,5.383-22.823c-3.616-5.847-1.863-14.053,1.454-26.873c3.564-13.774,7.998-30.916-1.293-46.593
                        c-4.668-7.876-14.836-10.479-22.719-5.809c-7.878,4.669-10.479,14.841-5.809,22.719c2.26,3.812-0.051,12.74-2.284,21.376
                        C88.367,112.857,83.444,131.88,94.768,150.19z"/>
                      <path d="M147.855,150.189c3.136,5.073,8.564,7.864,14.12,7.864c2.975,0,5.987-0.801,8.703-2.479
                        c7.79-4.815,10.201-15.035,5.385-22.823c-3.616-5.848-1.864-14.054,1.453-26.875c3.564-13.773,7.998-30.915-1.292-46.591
                        c-4.667-7.876-14.841-10.481-22.719-5.811s-10.48,14.841-5.811,22.719c2.261,3.813-0.05,12.742-2.283,21.377
                        C141.457,112.857,136.535,131.88,147.855,150.189z"/>
                      <path d="M16.582,391.851C7.424,391.851,0,399.276,0,408.433s7.424,16.582,16.582,16.582h20.576l28.334-33.164H16.582z"/>
                      <path d="M495.418,391.851H163.49l-7.687,25.756c-0.762,2.574-1.754,5.043-2.947,7.408h342.563
                        c9.158,0,16.582-7.424,16.582-16.582S504.576,391.851,495.418,391.851z"/>
                      <path d="M160.844,237.18c-9.158,0-16.582,7.424-16.582,16.582c0,3.38,1.017,6.521,2.754,9.143
                        c2.969,4.48,8.051,7.439,13.828,7.439h18.512c5.973,0,11.193-3.169,14.112-7.906c1.556-2.526,2.47-5.491,2.47-8.676
                        c0-9.158-7.424-16.582-16.582-16.582H160.844z"/>
                      <path d="M232.352,237.18c-9.158,0-16.582,7.424-16.582,16.582c0,3.185,0.913,6.15,2.47,8.676
                        c2.919,4.738,8.138,7.906,14.112,7.906h18.512c5.973,0,11.193-3.169,14.112-7.906c1.557-2.526,2.47-5.491,2.47-8.676
                        c0-9.158-7.424-16.582-16.582-16.582H232.352z"/>
                      <path d="M268.216,95.742c8.921,12.232,25.326,15.775,38.34,8.932c2.897-2.114,6.243-4.553,10.124-7.384
                        c3.879-2.829,7.223-5.268,10.12-7.38c10.493-10.301,12.132-27.004,3.211-39.236l-9.552-13.099
                        c-9.807-13.447-28.658-16.398-42.106-6.591l-13.099,9.553c-13.447,9.807-16.398,28.658-6.591,42.106L268.216,95.742z"/>
                      <path d="M41.677,354.822c3.587,0,7.727-0.001,12.53-0.001c4.801,0.001,8.939,0.001,12.525,0.001
                        c14.548-2.139,25.714-14.668,25.714-29.809v-16.212c0-16.644-13.492-30.136-30.137-30.136H46.1
                        c-16.644,0-30.136,13.492-30.136,30.136v16.212C15.964,340.152,27.131,352.682,41.677,354.822z"/>
                      <path d="M457.174,361.028c21.12,0,38.242-17.121,38.242-38.24c0.002-21.12-17.119-38.241-38.239-38.241H154.481
                        c-15.869,0-29.48,9.668-35.266,23.435c-1.047,1.684-1.907,3.518-2.496,5.51l-24.055,80.592l-47.416,55.497
                        c-8.099,9.468-6.989,23.707,2.478,31.806c9.467,8.101,23.707,6.991,31.807-2.476l49.468-57.903
                        c0.381-0.444,0.72-0.909,1.06-1.373c2.022-2.349,3.603-5.138,4.539-8.304l15.105-50.609c1.565,0.195,3.156,0.306,4.773,0.306
                        H457.174z"/>
                    </g>
                  </g>
                </g>
                </svg>
                <span>Cupping Therapy</span>
              </span>
            </li>
            <li className={`st-tab-title ${isActive === 3 ? "active" : ""}`} onClick={() => setIsActive(3)}>
              <span className="st-dip-blue-box">
              <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <g>
                    <path class="st0" d="M188.858,102.634c26.588,10.645,56.793-2.287,67.428-28.874C266.941,47.171,254,16.966,227.411,6.33
                      c-26.597-10.654-56.782,2.278-67.438,28.876C149.339,61.794,162.251,91.98,188.858,102.634z"/>
                    <path class="st0" d="M502.664,130.434L433.106,13.819c-5.298-9.872-16.011-14.946-26.5-13.607c-0.245,0.02-0.489,0.078-0.723,0.098
                      c-0.773,0.127-1.555,0.294-2.326,0.479c-0.46,0.127-0.929,0.225-1.378,0.371c-1.604,0.5-3.177,1.144-4.702,1.965
                      c-2.835,1.526-6.031,3.618-8.045,5.905l-76.508,59.256l-55.668,42.775c-23.382,15.122-41.496,14.389-62.58,9.56L30.582,74.052
                      C19.918,70.836,8.647,76.877,5.441,87.551c-3.206,10.664,2.834,21.935,13.509,25.151l156.39,60.624
                      c6.882,2.014,18.445,18.456,26.362,27.233c11.564,12.864,55.62,78.493,159.9,80.839c6.402,0.147,7.996,1.76,8.788,6.159
                      l19.677,200.758c0,13.078,10.606,23.684,23.685,23.684c13.069,0,23.684-10.606,23.684-23.684c0,0,2.336-257.004,2.63-261.383
                      c0.352-5.386,3.734-8.445,8.524-12.707c0,0,40.244-39.609,40.947-40.068C509.81,160.736,510.768,143.748,502.664,130.434z
                      M372.159,181.245c-4.887,3.538-22.59,6.95-29.863,6.822c-42.638-0.684-46.294-35.238-33.401-48.132l35.913-33.723l51.622-47.438
                      l36.188,74.955L372.159,181.245z"/>
                  </g>
                </svg>
                <span>Fitness</span>
              </span>
            </li>
          </ul>
          <div className="st-height-b25 st-height-lg-b25" />
          <div className="tab-content">
            <div id="Crutches" className={`st-tab ${isActive === 0 ? "active" : ""}`}>
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/rehab.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            <span>Rééducation</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Rééducation Neurologique, Rhumatologique et Traumatologique
                          </h4>
                          <div className="st-imagebox-text">
                            Nos séances de rééducation sont conçues pour les patients souffrant de troubles neurologiques,
                            rhumatologiques ou après un traumatisme. Grâce à une prise en charge personnalisée,
                            nous aidons à restaurer la mobilité, la coordination et à améliorer la qualité de vie.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="X-ray" className={`st-tab ${isActive === 1 ? "active" : ""}`}>
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/massage.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            <span>Massage</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Massages Thérapeutiques, Relaxants et Anti-Cellulite{" "}
                          </h4>
                          <div className="st-imagebox-text">
                            Nous offrons des massages thérapeutiques pour soulager les tensions musculaires, réduire le stress et favoriser la détente.
                            Ces soins sont également utiles pour améliorer la circulation et favoriser la guérison.
                            Nous proposons aussi des massages ciblés pour réduire l'apparence de la cellulite,
                            améliorer l'élasticité de la peau et favoriser le drainage lymphatique.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="Pulmonary" className={`st-tab ${isActive === 2 ? "active" : ""}`}>
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/cupping.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            <span>Cupping Therapy</span>
                          </h2>
                          <div className="st-imagebox-text">
                            Le cupping therapy est une technique ancestrale utilisée pour réduire les douleurs,
                            éliminer les toxines et stimuler la circulation sanguine.
                            Idéal pour les patients souffrant de douleurs musculaires chroniques ou de tensions.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="Cardiology" className={`st-tab ${isActive === 3 ? "active" : ""}`}>
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/fitness.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            <span>Fitness</span>
                          </h2>
                          <div className="st-imagebox-text">
                            Nos programmes de fitness sont adaptés à vos objectifs personnels,
                            que ce soit pour retrouver la forme après une blessure ou simplement pour adopter un mode de vie plus sain.
                            Nos experts vous guident à chaque étape pour garantir des résultats optimaux.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default Department;

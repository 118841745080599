import React from 'react';
import SectionHeading from '../SectionHeading/SectionHeading';
import Specialists from '../Specialists/Specialists';


const SpecialistsSlider = ({ data }) => {
  return (
    <section id="doctors">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Notre spécialiste"
        subTitle="Diplômée en kinésithérapie et avec une solide expérience dans le domaine, notre spécialiste est passionnée par <br /> l’aide aux personnes pour améliorer leur mobilité, soulager la douleur et renforcer leur qualité de vie." />
      <div className="container">
        <div className='row d-flex justify-content-center'>
          {
            data.map((elements, index) => (
              <Specialists style = {{width: 270}} {...elements} key={index} />
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default SpecialistsSlider;

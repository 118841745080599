import React from 'react';
import Hero from '../Components/Hero/Hero';
import LocationInMap from '../Components/Map/LocationInMap';
import Department from '../Components/Department/Department';
import Appointment from '../Components/Appointment/Appointment';
import About from '../Components/About/About';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import MasonryGallery from '../Components/Gallery/Gallery';

const heroData = {
  bgImg: 'images/hero-bg.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-astrid.png',
    }
  ],
  title: ['Rééducation', 'Massage', 'Fitness'],
};

const aboutData = {
  title:
    'Chez CaKiReB,',
  subTitle:
    'Nous croyons que chaque patient mérite une approche personnalisée, adaptée à ses besoins spécifiques. Grâce à notre expertise en kinésithérapie et rééducation fonctionnelle, nous aidons nos patients à retrouver mobilité et bien-être, que ce soit pour soulager des douleurs chroniques, récupérer après une blessure ou améliorer leur qualité de vie. <br /><br /> Nos traitements sont basés sur une évaluation approfondie et une compréhension de chaque condition, permettant ainsi des solutions sur mesure. Avec une équipe de professionnels expérimentés et dévoués, nous nous engageons à offrir des soins de qualité dans un cadre chaleureux et accueillant <br /><br /> Nous combinons des méthodes de rééducation modernes avec des techniques traditionnelles de relaxation, telles que le massage thérapeutique, pour garantir des résultats durables. Que vous soyez ici pour un suivi après un accident ou pour améliorer votre bien-être général, nous sommes à vos côtés à chaque étape du processus de guérison',

  avater: {
    img: 'images/avatar1cakireb.png',
    name: 'Astrid',
    designation: 'Fondatrice & kinésithérapeute',
  },
  timeTable: [
    {
      day: 'Lundi',
      startTime: '8:00',
      endTime: '17:00',
    },
    {
      day: 'Mardi',
      startTime: '8:00',
      endTime: '17:00',
    },
    {
      day: 'Mecredi',
      startTime: '8:00',
      endTime: '17:00',
    },
    {
      day: 'Jeudi',
      startTime: '8:00',
      endTime: '17:00',
    },
    {
      day: 'Vendredi',
      startTime: '8:00',
      endTime: '17:00',
    },
    {
      day: 'Samedi',
      startTime: '8:00',
      endTime: '15:00',
    },
  ],
  contact: '+237 695 598 286 / +237 653 222 924',
};

const specialistData = [
  {
    img: 'images/astrid.jpg',
    name: 'Astrid',
    designation: 'Kinésithérapeute',
    authorLink: '/doctor-profile',
  },
];


const mapLocationURL =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.6258118723536!2d9.7413874!3d4.0963222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10610ff1b5a306a3%3A0xb6155697aa96f7a9!2sCakireb!5e0!3m2!1sen!2sae!4v1730616321132!5m2!1sen!2sae';

const Home = () => {
  return (
    <>
      <Hero data={heroData} />
      <About data={aboutData} />
      <Department />
      <Appointment />
      <SpecialistsSlider data={specialistData} />
      <hr />
      <MasonryGallery />
      <LocationInMap data={mapLocationURL} />
    </>
  );
};

export default Home;

import React from 'react';

const Specialists = ({ img, name, designation }) => {
  return (
    <div className='col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3'>
      <div className="st-member st-style1 st-zoom">
        <div className="st-member-img">
          <img src={img} alt={img} className="st-zoom-in" />
        </div>
        <div className="st-member-meta">
          <div className="st-member-meta-in">
            <h3 className="st-member-name">{name}</h3>
            <div className="st-member-designation">{designation}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specialists;

import SectionHeading from '../SectionHeading/SectionHeading';

const Appointment = () => {

  return (
    <section id="appointment" className="st-shape-wrap st-gray-bg">
      <div className="st-shape4">
        <img src="/shape/section_shape.png" alt="/shape/section_shape.png" />
      </div>
      <div className="st-shape6">
        <img src="/shape/contact-shape3.svg" alt="/shape/contact-shape3.svg" />
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Prendre rendez-vous"
        subTitle="Pour fixer un rendez-vous, contactez-nous par téléphone au <b>+237 695 598 286</b> ou <b>+237 653 222 924</b>, <br/>ou envoyez-nous un e-mail à <b>cakirebkine@gmail.com</b>. Nous reviendrons vers vous dans les plus brefs délais." />
    </section>
  )
}

export default Appointment
